import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  Text, CircularProgress,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ethers } from "ethers"
import {
  useParams,
} from "react-router-dom";
import { ExternalLinkIcon } from '@chakra-ui/icons'


export const Form = () => {
  let [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contenthash, setContenthash] = useState('');
  const handleInputChange = (e: any) => setInput(e.target.value);
  const handleSubmit = async (e: any) => {
    setError('');
    setSuccess('');
    setContenthash('');

    e.preventDefault();
    setIsLoading(true);
    try {
      let provider: any;
      provider = new ethers.providers.JsonRpcProvider("https://cloudflare-eth.com");
      const resolver = await provider.getResolver(input);
      if (!resolver) {
        setError("Can't resolve domain or can't find IPFS content record for " + input);
      }
      let result = await resolver.getContentHash();
      if (result === '') {
        setError("Can't resolve domain or can't find IPFS content record for " + input);
      }
      else {
        setSuccess("Resolved " + input + ", url provided below.");
        let redurl='/'+input;
        setContenthash(redurl);
      }
      setIsLoading(false);
    } catch (err) {
      setError("Can't resolve domain or can't find IPFS content record.");
      setIsLoading(false);
    }
  };

  const { id } = useParams();
  if (id!==undefined) {
    input=id;
  }

  const isENSDomain = (/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(input) === false)

  return (
    <Flex
      minH={'80vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={6} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'3xl'}>Fill in Your Web3 ENS Domain</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            to create your web2 <Link color={'blue.400'}>page</Link> ✌️
          </Text>
          <Text fontSize={'lg'} color={'gray.600'}>
            Decentr will look up the IPFS address in the content field of your ens domain records and provide the redirect url that points to the IPFS address.
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <form onSubmit={handleSubmit} >
              <FormControl isInvalid={isENSDomain} isRequired>
                <FormLabel htmlFor="ensdomain" >ENS Domain </FormLabel>
                <Input
                  id="ensdomain"
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                />
                {!isENSDomain ? (
                  <FormHelperText>As Created On <Link color={'blue.400'}>https://ens.domains/</Link> e.g. fleekhq.eth</FormHelperText>
                ) : (
                    <FormErrorMessage>ENS Domain is required (as Created On ENS Domain Website)</FormErrorMessage>
                  )}
              </FormControl>
              <Stack spacing={10}>
                {(error !== '') ? (
                  <Alert
                    status='error'
                    mt='6'
                    fontSize='sm'>
                    <AlertIcon />
                    {error}
              </Alert>
                ) : (
                    <></>
                  )}
                {(success !== '') ? (
                  <Alert
                    status='success'
                    mt='6'
                    fontSize='sm'>
                    <AlertIcon />
                    <AlertDescription maxWidth='sm'>
                    {success}
                    </AlertDescription>
                  </Alert>
                ) : (<></>)
                }
                {(contenthash !== '') ? (
                  <Link href={contenthash} isExternal>
                    {input} <ExternalLinkIcon mx='2px' />
                  </Link>
                ) : (<></>)
                }
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress isIndeterminate size="24px" color="teal" />
                  ) : (
                      'Generate The Link Now'
                    )}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
