import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Nav } from "./components/Nav"
import { Form } from "./components/Form"
import { FormRed } from "./components/FormRed"
import { Footer } from "./components/Footer"

import { extendTheme } from '@chakra-ui/react'

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    DEFAULT: '#0046E5',
    '50': '#9EBBFF',
    '100': '#89ADFF',
    '200': '#6091FF',
    '300': '#3875FF',
    '400': '#0F58FF',
    '500': '#0046E5',
    '600': '#0035AD',
    '700': '#002475',
    '800': '#00133D',
    '900': '#000105'
  },
}

const theme = extendTheme({ colors })

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
    <Nav />
    <Form />
    <Footer />
    </Box>
  </ChakraProvider>
)

export const AppRed = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
    <Nav />
    <FormRed />
    <Footer />
    </Box>
  </ChakraProvider>
)
